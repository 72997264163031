<template>
  <div>
    <div class="m-b-30">
      <input @keypress.enter.prevent="onEnter" v-model="query" ref="client-search" type="text" class="form-control" id="client-search" placeholder="Type here to filter">
    </div>

    <div class="table-responsivex">
      <table class="table table-striped table-bordered table-hover">
        <tbody data-toggle="buttons" id="bookings_topics">
          <tr v-for="course in matchingCourses" :key="course.id">
            <td class="nopadding">
              <label class="btn" @click="select(course.id)">
                <input type="radio" :value="course.id">{{ course.title }}
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  data: function () {
    return {
      courses: [],
      course_id: null,
      query: ""
    }
  },
  mounted() {
    this.focusInput()
    this.courses = gon.courses
  },
  computed: {
    matchingCourses() {
      const fuse = new Fuse(this.courses, {
        keys: ['title']
      })

      const q = this.query.trim()

      if (q === "") {
        return this.courses
      } else {
        const results = fuse.search(q)
        return results.map(result => result.item)
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs["client-search"].focus()
    },
    select(course_id) {
      this.course_id = course_id
      const el = document.getElementById("draft_event_draft_bookings_attributes_0_course_id")
      el.value = course_id
      setTimeout(() => { el.closest("form").submit() }, 100)
    },
    onEnter() {
      this.query = this.query.trim() + " "
    }
  }
}
</script>

<style scoped>
</style>
