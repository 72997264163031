import Vue from 'vue/dist/vue.esm'
import BookingsList from '../bookings_list.vue'
import SearchClient from '../search_client.vue'
import SearchCourse from '../search_course.vue'
import SearchUser from '../search_user.vue'
import moment from 'moment'

Vue.prototype.moment = moment

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("bookings-list-id")) {
    const app = new Vue({ el: '#bookings-list-id', components: { BookingsList } })
  }
  if (document.getElementById("search-client-id")) {
    const app = new Vue({ el: '#search-client-id', components: { SearchClient } })
  }
  if (document.getElementById("search-course-id")) {
    const app = new Vue({ el: '#search-course-id', components: { SearchCourse } })
  }
  if (document.getElementById("search-user-id")) {
    const app = new Vue({ el: '#search-user-id', components: { SearchUser } })
  }
})

console.log('Hello World from v1application')
