<template>
  <div class="booking-list-vue">
    <h1>Bookings ({{ matchingEvents.length }})
      <a href="/bookings/reload" style="color: white; background: #95a5a6; padding: 6px; border-radius: 10px; font-size: 10px; cursor: pointer; text-decoration: none;"
        @mouseover="reload_hover = true"
        @mouseleave="reload_hover = false"
        :class="{ activelink: reload_hover }"
        >{{ cache_info }}</a>
    </h1>
    <div class="m-b-30">
      <div class="row">
        <div class="col-md-4">
          <input @keypress.enter.prevent="onEnter"
            v-model="client_filter" ref="event-search"
            type="text" class="form-control"
            placeholder="Type here to filter by client or trainer...">
        </div>
        <div class="col-md-2">
          <select v-model="date_filter" class="form-control" @keypress.enter.prevent="onEnter">
            <optgroup label="Near days">
              <option :key="1" :value="yesterday">Yesterday</option>
              <option :key="2" :value="today">Today</option>
              <option :key="3" :value="tomorrow">Tomorrow</option>
            </optgroup>
            <optgroup label="Near weeks">
              <option :key="4" :value="last_week">Last week</option>
              <option :key="5" :value="this_week">This week</option>
              <option :key="6" :value="next_week">Next week</option>
            </optgroup>
            <optgroup label="Near months">
              <option :key="7" :value="prevMonthValue">Last {{ moment().subtract(1, "M").format("MMMM") }}</option>
              <option :key="8" :value="currentMonthValue">This {{ moment().format("MMMM") }}</option>
              <option :key="9" :value="nextMonthValue">Next {{ moment().add(1, "M").format("MMMM") }}</option>
            </optgroup>
            <optgroup label="Other months">
              <option v-for="item in months_selection" :key="item.idx" :value="item.value">
                {{ item.text }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="col-md-3">
          <input @keypress.enter.prevent="onEnter"
            v-model="start_date_filter"
            type="date" class="form-control">
        </div>
        <div class="col-md-3">
          <input @keypress.enter.prevent="onEnter"
            v-model="end_date_filter"
            type="date" class="form-control">
        </div>
      </div>
    </div>

    <div class="table-responsivex">
      <table class="table table-striped table-bordered table-hover">
        <tbody data-toggle="buttons" id="bookings_topics">
          <tr v-for="event in matchingEvents" :key="event.id" v-on:click="redirect(event.url)" class="cursor-pointer">
            <td class="td">
              <div class="row">
                <div class="col-md-2 date-group">
                  <span class="date-start">{{ event.start }}</span>
                </div>
                <div class="col-md-6">
                  <div class="company">{{ event.client_fullname }}</div>
                  <ul class="title-list">
                    <li>{{ event.title_hash.course_title_1 }}</li>
                    <li v-if="event.title_hash.course_title_2">{{ event.title_hash.course_title_2 }}</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div class="trainer-name">{{ event.title_hash.trainer_name }}</div>
                  <div class="rate">{{ event.title_hash.rate }}</div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  data() {
    return {
      events: [],
      cache_timestamp: 0,
      event_id: null,
      client_filter: "",
      start_date_filter: "",
      end_date_filter: "",
      months_selection: [],
      date_filter: "",
      reload_hover: false
    }
  },
  mounted() {
    const current_moment = moment()

    this.focusInput()
    this.events            = gon.events_with_timestamp.events
    this.cache_timestamp   = gon.events_with_timestamp.timestamp
    this.date_filter       = [current_moment.startOf("day").unix(), current_moment.endOf("day").unix()].join(",")
    this.start_date_filter = current_moment.format('YYYY-MM-DD')
    this.end_date_filter   = this.start_date_filter
    this.months_selection  = this.initDateFilters()
  },
  watch: {
    date_filter: function(val) {
      if (val !== "") {
        const parts = val.split(",")
        this.start_date_filter = moment.unix(parts[0]).format('YYYY-MM-DD')
        this.end_date_filter = moment.unix(parts[1]).format('YYYY-MM-DD')
      }
    },
    start_date_filter: function(val) {
      const parts = val.split("-")
      const dd = parts[2]

      if (this.start_date_filter.substr(0, 7) === this.end_date_filter.substr(0, 7)) {
        // Disregard
      } else {
        this.date_filter = ""
      }

      if (moment(this.start_date_filter, "YYYY-MM-DD").unix() > moment(this.end_date_filter, "YYYY-MM-DD").unix()) {
        this.end_date_filter = this.start_date_filter
      }
    },
    end_date_filter: function(val) {
      const parts = val.split("-")
      const dd = parts[2]

      if (this.start_date_filter.substr(0, 7) === this.end_date_filter.substr(0, 7)) {
        // Disregard
      } else {
        this.date_filter = ""
      }

      if (moment(this.start_date_filter, "YYYY-MM-DD").unix() > moment(this.end_date_filter, "YYYY-MM-DD").unix()) {
        this.start_date_filter = this.end_date_filter
      }
    }
  },
  computed: {
    cache_info() {
      if (this.reload_hover) {
        return "Click to reload, data was fetched " + this.cache_old
      } else {
        return this.cache_old
      }
    },
    cache_old() {
      return moment.unix(this.cache_timestamp).fromNow()
    },
    yesterday() {
      const d = moment().add(-1, "day")
      return [d.startOf("day").unix(), d.endOf("day").unix()].join(",")
    },
    today() {
      const d = moment()
      return [d.startOf("day").unix(), d.endOf("day").unix()].join(",")
    },
    tomorrow() {
      const d = moment().add(1, "day")
      return [d.startOf("day").unix(), d.endOf("day").unix()].join(",")
    },
    last_week() {
      const d = moment().startOf("week").add(-7, "day")
      return [d.unix(), d.endOf("week").unix()].join(",")
    },
    this_week() {
      const d = moment().startOf("week")
      return [d.unix(), d.endOf("week").unix()].join(",")
    },
    next_week() {
      const d = moment().startOf("week").add(7, "day")
      return [d.unix(), d.endOf("week").unix()].join(",")
    },
    prevMonthValue() {
      const d = moment().add(-1, "M")
      return [d.startOf("month").unix(), d.endOf("month").unix()].join(",")
    },
    currentMonthValue() {
      const d = moment()
      return [d.startOf("month").unix(), d.endOf("month").unix()].join(",")
    },
    nextMonthValue() {
      const d = moment().add(1, "M")
      return [d.startOf("month").unix(), d.endOf("month").unix()].join(",")
    },
    matchingEvents() {
      let date_filtered_events = this.events.filter(row => {
        return (row.start_timestamp >= moment(this.start_date_filter, "YYYY-MM-DD").unix()
             && row.start_timestamp <= moment(this.end_date_filter, "YYYY-MM-DD").endOf("day").unix())
      })

      const q = this.client_filter.trim()

      if (q === "") {
        return date_filtered_events
      } else {
        const search_filtered_events = new Fuse(date_filtered_events, { keys: ["client_fullname", "title_hash.trainer_name"] })
                                             .search(q)
                                             .map(result => result.item)
        return search_filtered_events
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs["event-search"].focus()
    },
    select(event_id) {
      this.event_id = event_id
      const el = document.getElementById("search_event_id")
      el.value = event_id
      setTimeout(() => { el.closest("form").submit() }, 100)
    },
    onEnter() {
      this.client_filter = this.client_filter.trim() + " "
    },
    initDateFilters() {
      let hold_date = moment().subtract(5, 'M').startOf('month')
      return [...Array(12)].map((_, idx) => {
        const hash = {
          idx: idx + 1000,
          text: hold_date.startOf("month").format("MMM YYYY"),
          value: [hold_date.startOf("month").unix(), hold_date.endOf("month").unix()].join(",")
        }
        hold_date = hold_date.add(1, 'M')
        return hash
      })
    },
    redirect(url) {
      window.location.href = url;
    }
  }
}
</script>
