<template>
  <div>
    <div class="m-b-30">
      <input @keypress.enter.prevent="onEnter"
        v-model="query" ref="client-search"
        type="text" class="form-control"
        id="client-search"
        placeholder="Type here to filter by either email or name"
        autocomplete="off">
    </div>

    <div class="table-responsivex">
      <table class="table table-striped table-bordered table-hover">
        <tbody data-toggle="buttons" id="bookings_topics">
          <tr v-for="user in matchingUsers" :key="user.id">
            <td class="nopadding">
              <label class="btn" @click="select(user.id)">
                {{ user.email }}
              </label>
            </td>
            <td class="nopadding">
              <label class="btn" @click="select(user.id)">
                <input type="radio" :value="user.id" name="draft_event[user_id]">{{ user.name }} {{ user.surname }}
              </label>
            </td>
            <td class="nopadding">
              <div class="pl-14" :class="user.role">
                {{ capitalize(user.role) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  data: function () {
    return {
      users: [],
      user_id: null,
      query: ""
    }
  },
  mounted() {
    this.focusInput()
    this.users = gon.users
  },
  computed: {
    matchingUsers() {
      const fuse = new Fuse(this.users, {
        keys: ['email', 'name', 'surname']
      })

      const q = this.query.trim()

      if (q === "") {
        return this.users
      } else {
        const results = fuse.search(q)
        return results.map(result => result.item)
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs["client-search"].focus()
    },
    select(user_id) {
      this.user_id = user_id
      const el = document.getElementById("search_user_id")
      el.value = user_id
      setTimeout(() => { el.closest("form").submit() }, 100)
    },
    onEnter() {
      this.query = this.query.trim() + " "
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}
</script>

<style scoped>
.pl-14 {
  padding-left: 14px;
}
.client {
  color: #18BC9C;
}
.company {
  color: #000000;
}
.trainer {
  font-weight: 600;
}
</style>
