<template>
  <div>
    <div class="m-b-30">
      <input @keypress.enter.prevent="onEnter" v-model="query" ref="client-search" type="text" class="form-control" id="client-search" placeholder="Type here to filter">
    </div>

    <div class="table-responsivex">
      <table class="table table-striped table-bordered table-hover">
        <tbody data-toggle="buttons" id="bookings_topics">
          <tr v-for="user in matchingUsers" :key="user.id">
            <td class="nopadding">
              <label class="btn" @click="select(user.id)"><input type="radio" :value="user.id" name="draft_event[user_id]">{{ user.name }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  data: function () {
    return {
      users: [],
      user_id: null,
      query: ""
    }
  },
  mounted() {
    this.focusInput()
    this.users = gon.users
  },
  computed: {
    matchingUsers() {
      const fuse = new Fuse(this.users, {
        keys: ['name']
      })

      const q = this.query.trim()

      if (q === "") {
        return this.users
      } else {
        const results = fuse.search(q)
        return results.map(result => result.item)
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs["client-search"].focus()
    },
    select(user_id) {
      this.user_id = user_id
      const el = document.getElementById("draft_event_user_id")
      el.value = user_id
      setTimeout(() => { el.closest("form").submit() }, 100)
    },
    onEnter() {
      this.query = this.query.trim() + " "
    }
  }
}
</script>

<style scoped>
</style>
